import TagManager from 'react-gtm-module';
import { addSessionId } from "@utils/addUserSessionId";
import mixpanel from "@utils/mixpanel";
import {useMixpanel} from "@hooks";

export const initGTMforButtons = (data) => {
  console.log('initGTMforButtons-data: ', data);

  switch (data.eventType) {
    // Подія "Викликати консультанта" (клік на кнопку)
    case 'consultant_request_click': {
      TagManager.dataLayer({
        dataLayer: {},
      });
      TagManager.dataLayer({
        dataLayer: addSessionId({
          event: 'consultant_request_click',
          event_type: 'custom_event',
        }),
      });
      mixpanel.track("consultant_request_click", {
        "event_type": "custom_event"
      })
      // useMixpanel('configuration', {
      //   qwe: '33333333333333333',
      //   page_name: 'Конфігуратор',
      //   configuration_step: sessionStorage.getItem('configuratorActiveStep'),
      //   // configuration_step: configuratorActiveStep,
      //   configuration_step_name: 'Конфігуратор - обрати колір',
      //   model_name: state?.car.label,
      // });
      break;
    }
    // Подія "Викликати консультанта" (успішна відправка форми)
    case 'consultant_request_submit': {
      TagManager.dataLayer({
        dataLayer: {},
      });
      TagManager.dataLayer({
        dataLayer: addSessionId({
          event: 'consultant_request_submit',
          event_type: 'custom_event',
        }),
      });
      mixpanel.track("consultant_request_submit", {
        "event_type": "custom_event"
      })
      break;
    }
    // Подія "Запис на тест-драйв" (успішна відправка форми)
    case 'test-drive_submit': {
      TagManager.dataLayer({
        dataLayer: {},
      });
      TagManager.dataLayer({
        dataLayer: addSessionId({
          event: 'test-drive_submit',
          event_type: 'custom_event',
          model: data.model_name, // назва моделі на яку оформлено тест-драйв
        }),
      });
      break;
    }
    // Подія клік/тап по кнопкам (по можливості)
    case 'custom_click': {
      TagManager.dataLayer({
        dataLayer: {},
      });
      TagManager.dataLayer({
        dataLayer: addSessionId({
          event: 'custom_click',
          click_text: data.click_text,
          click_link: data.click_link,
        }),
      });
      mixpanel.track("custom_click", {
        click_text: data.click_text,
        click_link: data.click_link,
      })
      break;
    }
    // Конфігурація події для кожного кроку конфігурації. при успішному досягненні наступного кроку передається назва кроку та номер.
    case 'configuration': {
      console.log('configuration-data: ', data);
      TagManager.dataLayer({
        dataLayer: {},
      });
      TagManager.dataLayer({
        dataLayer: addSessionId({
          event: 'configuration',
          configuration_step: data.configuration_step, // 1,2,3,4
          configuration_step_name: data.configuration_step_name, // Двигун, Колір
          model_name: data.model_name,
        }),
      });
      break;
    }
    // Конфігурація успішна. Конфігурація закінчена. Екран результатів.
    case 'configuration_complete': {
      TagManager.dataLayer({
        dataLayer: {},
      });
      TagManager.dataLayer({
        dataLayer: addSessionId({
          event: 'configuration_complete',
          model_name: data.model_name,
        }),
      });
      break;
    }
    // Конфігурація -- Зберегти результат
    case 'configuration_save': {
      TagManager.dataLayer({
        dataLayer: {},
      });
      TagManager.dataLayer({
        dataLayer: addSessionId({
          event: 'configuration_save',
          model_name: data.model_name, // Назва моделі авто
        }),
      });
      mixpanel.track("configuration_save", {
        model_name: data.model_name
      })
      break;
    }
    // Порівняння створено. Екран порівняння
    case 'compare_complete': {
      TagManager.dataLayer({
        dataLayer: {},
      });
      TagManager.dataLayer({
        dataLayer: addSessionId({
          event: 'compare_complete',
          model_name: data.model_name, // Назва моделі авто
        }),
      });
      break;
    }
    // Порівняння -- Зберегти результат
    case 'compare_save': {
      TagManager.dataLayer({
        dataLayer: {},
      });
      TagManager.dataLayer({
        dataLayer: addSessionId({
          event: 'compare_save',
          model_name: data.model_name, // Назва моделі авто
        }),
      });
      break;
    }
    // Cеанс завершено (спрацювало автоматичне блокування, користувач натиснув завершити)
    case 'session_end': {
      TagManager.dataLayer({
        dataLayer: {},
      });
      TagManager.dataLayer({
        dataLayer: {
          event: 'session_end',
        },
      });
      mixpanel.track("session_end")
      break;
    }
    default:
      return null;
  }
};
