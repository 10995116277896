// Import Mixpanel SDK
import mixpanel from 'mixpanel-browser';

// Import Mixpanel TOKEN
import { MIXPANEL_TOKEN } from "../constants";

// Initialize Mixpanel
mixpanel.init(MIXPANEL_TOKEN, { track_pageview: false });

export default mixpanel;
